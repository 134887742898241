<script>
/* COMPOSANTS */
import AkFormView from "@components/layout/AkFormView";
import AkCalendar from "@components/input/AkCalendar";
import AkCheckbox from "@components/input/AkCheckbox";
import AkDropdown from "@components/input/AkDropdown";

/* MIXINS */
import randomRef from "@mixins/randomRef";
import loaderMixin from "@mixins/loaderMixin";
import dateFormatter from "@mixins/dateFormatter";
import circuitMixin from "@mixins/circuitMixin";
import roleMixin from "@mixins/roleMixin";

/* SERVICES */
import agerService from "@services/agerService";
import uerService from "@services/uerService";
import ceiService from "@services/ceiService";
import cei2Service from "@services/cei2Service";
import forecastSheetService from "@services/forecastSheetService";
import linkSheetService from "@services/linkSheetService";
import circuitService from "@services/circuitService";

export default {
  components: {AkFormView, AkCalendar, AkCheckbox, AkDropdown},
  mixins: [randomRef, loaderMixin, dateFormatter, circuitMixin, roleMixin],
  metaInfo() {
    return {
      title: "classic_daily_summary.list",
    }
  },
  data() {
    return {
      year: 0,
      yearList: [],
      agerList: [],
      uerList: [],
      ceiList: [],
      cei2List: [],
      circuitList: [],
      circuitListToDisplay: [],
      ceiListToDisplay: [],
      linkSheetList: [],
      forecastSheetList: [],
      linkSheetListToDisplay: [],
      forecastSheetListToDisplay: [],
      date: new Date(),
      minDate: new Date(),
      maxDate: new Date(),
      displayVirtualCei: false,
    }
  },
  mounted() {
    this.getRef().showTotalLoader();
  
    let p1 = linkSheetService.findAllYears().then(data => {
      if (!this.canOnlyViewArchivedCD()) {
        this.yearList.push({value: 0, label: this.$t('current_year')});
      }
      data.forEach(year => this.yearList.push({value: year, label: year + ' - ' + (year + 1)}));
    });
  
    Promise.all([p1]).then(() => {
      if (this.canOnlyViewArchivedCD()) {
        this.year = this.yearList[0].value;
        this.initData();
        this.loading = false;
        this.getRef().hideLoader();
      } else {
        let p2, p3, p4, p5, p6, p7, p8;
        p2 = agerService.findAllCurrent();
        p2.then(data => this.agerList = data);
        p3 = uerService.findAllCurrent();
        p3.then(data => this.uerList = data);
        p4 = ceiService.findAllCurrent();
        p4.then(data => this.ceiList = data);
        p5 = cei2Service.findAllCurrent();
        p5.then(data => this.cei2List = data);
        p6 = circuitService.findAllCurrent()
        p6.then(data => this.circuitList = data);
        p7 = linkSheetService.findAllCurrentValidatedWithRows();
        p7.then(data => this.linkSheetList = data);
        p8 = forecastSheetService.findAllCurrentValidatedWithRows();
        p8.then(data => this.forecastSheetList = data);
  
        Promise.all([p2, p3, p4, p5, p6, p7, p8]).then(() => this.afterPromise());
      }
    });
  },
  methods: {
    initData() {
      let linkSheetDateList = [];
      let forecastSheetDateList = [];
  
      this.linkSheetListToDisplay = this.linkSheetList.filter(l => this.sameDay(l.linkSheet.date, this.date));
      this.forecastSheetListToDisplay = this.forecastSheetList.filter(f => this.sameDay(f.forecastSheet.date, this.date));
      this.linkSheetList.forEach(l => linkSheetDateList.push(l.linkSheet.date));
      this.forecastSheetList.forEach(f => forecastSheetDateList.push(f.forecastSheet.date));
      this.minDate = this.findMinDateByDay(linkSheetDateList.concat(forecastSheetDateList));
      this.maxDate = this.findMaxDateByDay(linkSheetDateList.concat(forecastSheetDateList));
      if (this.date > this.maxDate) this.date = this.maxDate;
      if (this.date < this.minDate) this.date = this.minDate;
      this.refreshDisplay();
    },
    afterPromise() {
      this.loading = false;
      this.getRef().hideLoader();
      this.initData();
    },
    reload(year) {
      this.loading = true;
      this.getRef().showTotalLoader();
      let p1, p2, p3, p4, p5, p6, p7;
      if (year !== 0) {
        p1 = agerService.findAllByYear(year);
        p1.then(data => this.agerList = data);
        p2 = uerService.findAllByYear(year);
        p2.then(data => this.uerList = data);
        p3 = ceiService.findAllByYear(year);
        p3.then(data => this.ceiList = data);
        p4 = cei2Service.findAllByYear(year);
        p4.then(data => this.cei2List = data);
        p5 = circuitService.findAllByYear(year);
        p5.then(data => this.circuitList = data);
        p6 = linkSheetService.findAllByYearValidatedWithRows(year);
        p6.then(data => this.linkSheetList = data);
        p7 = forecastSheetService.findAllByYearValidatedWithRows(year);
        p7.then(data => this.forecastSheetList = data);
      } else {
        p1 = agerService.findAllCurrent();
        p1.then(data => this.agerList = data);
        p2 = uerService.findAllCurrent();
        p2.then(data => this.uerList = data);
        p3 = ceiService.findAllCurrent();
        p3.then(data => this.ceiList = data);
        p4 = cei2Service.findAllCurrent();
        p4.then(data => this.cei2List = data);
        p5 = circuitService.findAllCurrent()
        p5.then(data => this.circuitList = data);
        p6 = linkSheetService.findAllCurrentValidatedWithRows();
        p6.then(data => this.linkSheetList = data);
        p7 = forecastSheetService.findAllCurrentValidatedWithRows();
        p7.then(data => this.forecastSheetList = data);
      }
      Promise.all([p1, p2, p3, p4, p5, p6, p7]).then(() => this.afterPromise());
    },
    ceiListForAger(agerId) {
      return this.ceiListToDisplay.filter(c => c.agerId === agerId);
    },
    uerListForAger(agerId) {
      return this.uerList.filter(u => u.agerId === agerId);
    },
    ceiListForUer(uerId) {
      return this.ceiListToDisplay.filter(c => c.uerId === uerId);
    },
    addVirtualCeis() {
      this.ceiList.forEach(c => {
        if (c.cei2Id) this.circuitListToDisplay.forEach(ctd => {
          if (ctd.ceiId === c.id) {
            ctd.ceiId = c.cei2Id;
            ctd.ceiLabel = this.cei2List.filter(c2 => ctd.ceiId === c2.id)[0].label;
          }
        });
      });
    },
    refreshDisplay() {
      this.circuitListToDisplay = JSON.parse(JSON.stringify(this.circuitList));
      if (this.displayVirtualCei) this.addVirtualCeis();
      this.initCircuitListForClassicDailySummaryTable(this.circuitListToDisplay, this.forecastSheetListToDisplay, this.linkSheetListToDisplay, this.displayArchiveData );
      this.ceiListToDisplay = this.ceiList;
    },
    changeDate() {
      this.getRef().showTotalLoader();
  
      this.linkSheetListToDisplay = this.linkSheetList.filter(l => this.sameDay(l.linkSheet.date, this.date));
      this.forecastSheetListToDisplay = this.forecastSheetList.filter(f => this.sameDay(f.forecastSheet.date, this.date));
  
      this.refreshDisplay();
      this.getRef().hideLoader();
    },
    previousDay() {
      if (this.date.getTime() - (3600 * 1000 * 24) >= this.minDate.getTime())
        this.date = new Date(this.date.getTime() - (3600 * 1000 * 24));
    },
    nextDay() {
      if (this.date.getTime() + (3600 * 1000 * 24) <= this.maxDate.getTime())
        this.date = new Date(this.date.getTime() + (3600 * 1000 * 24));
    },
    forecastCell(circuit) {
      return circuit.forecastSheet ? '\u2715' : '';
    },
    forecastPatrolCell(circuit) {
      return circuit.forecastSheet && circuit.forecastSheet.forecastSheet.patrolStart ? '\u2715' : '';
    },
    forecastPrecurCell(circuit) {
      return circuit.forecastSheet && circuit.forecastSheet.forecastSheet.precurativeStart ? '\u2715' : '';
    },
    forecastCurCell(circuit) {
      return circuit.forecastSheet && circuit.forecastSheet.forecastSheet.curativeStart ? '\u2715' : '';
    },
    forecastPointsCell(circuit) {
      return circuit.forecastSheet && circuit.forecastSheet.forecastSheet.patrolStart ? '\u2715' : '';
    },
    linkStartCell(circuit) {
      return circuit.linkSheet && circuit.linkTypeStart ? '\u2715' : '';
    },
    linkInterCell(circuit) {
      return circuit.linkSheet && circuit.linkTypeInter ? '\u2715' : '';
    },
    linkEndCell(circuit) {
      return circuit.linkSheet && circuit.linkTypeEnd ? '\u2715' : '';
    },
    linkPatrolCell(circuit) {
      return circuit.linkRow && circuit.linkRow.patrol ? '\u2715' : '';
    },
    linkPrecurCell(circuit) {
      return circuit.linkRow && circuit.linkRow.precurative ? '\u2715' : '';
    },
    linkCurCell(circuit) {
      return circuit.linkRow && circuit.linkRow.curative ? '\u2715' : '';
    },
    linkPointsCell(circuit) {
      return circuit.linkRow && circuit.linkRow.singularPoint ? '\u2715' : '';
    },
    linkBeginHour(circuit) {
      return circuit.linkRow && circuit.linkRow.startHour ? this.formatHour(circuit.linkRow.startHour) : '';
    },
    linkEndHour(circuit) {
      return circuit.linkRow && circuit.linkRow.endHour ? this.formatHour(circuit.linkRow.endHour) : '';
    },
    linkSalt(circuit) {
      return circuit.linkRow && circuit.linkRow.drySaltTonnage ? circuit.linkRow.drySaltTonnage : '';
    },
    linkBrine(circuit) {
      return circuit.linkRow && circuit.linkRow.brineLiters ? circuit.linkRow.brineLiters : '';
    },
    linkTrafficCondition(circuit) {
      return circuit.linkRow && circuit.linkRow.trafficCondition ? circuit.linkRow.trafficCondition : '';
    }
  },
  computed: {
    title() {
      return this.$t('classic_daily_summary.title', [this.prettyFormatDate(this.date)]);
    },
    displayArchiveData(){
      return this.year !== 0;
    }
  },
  watch: {
    date() {
      this.changeDate();
    },
    displayVirtualCei() {
      this.refreshDisplay();
    },
    year(newType) {
      this.reload(newType);
    }
  }
}
</script>

<template v-if=!roleLoading>
  <AkFormView :ref="ref" :title="title" :displayGoBack=false>
    <template v-slot:form>
      <div class="form-row">
        <AkDropdown :label="$t('year')"
                    v-model="year"
                    :options=this.yearList
                    class-name="col-md-2"/>
        <AkCalendar v-model=date :label="$t('date')"
                    :maxDate=maxDate :minDate=minDate class-name="col-md-2"/>
        <div class="form-group">
          <label>&nbsp;</label>
          <span style="display: flex;">
            <span class="btn btn-xs btn-inverse-primary flex-center" style="margin: 0 10px 0 5px; height: 40px;" @click="previousDay()">
              <i class="fe fe-arrow-left"/>
            </span>
            <span class="btn btn-xs btn-inverse-primary flex-center" style="height: 40px;" @click="nextDay()">
              <i class="fe fe-arrow-right"/>
            </span>
          </span>
        </div>
      </div>
      <div class="form-row">
        <AkCheckbox v-model="displayVirtualCei" :label="$t('classic_daily_summary.virtual_cei')" class-name="col-md-2"/>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="card card-statistics">
            <div class="card-body">
              <div class="table-responsive forecast-table">
                <table style="display: block; overflow-x: auto;">
                  <thead>
                  <tr>
                    <th scope="colgroup" rowspan="2" colspan="6">{{ $t('classic_daily_summary.localisation') }}</th>
                    <th style="border-left: 2px solid #e9ecef !important;" scope="colgroup" colspan="5">{{ $t('classic_daily_summary.forecast') }}</th>
                    <th style="border-left: 2px solid #e9ecef !important;" scope="colgroup" colspan="15">{{ $t('classic_daily_summary.link') }}</th>
                  </tr>
                  <tr>
                    <th style="border-left: 2px solid #e9ecef !important;" scope="col" rowspan="2">{{ $t('classic_daily_summary.forecast_sheet') }}</th>
                    <th scope="colgroup" colspan="4">{{ $t('classic_daily_summary.intervention_types') }}</th>
                    <th style="border-left: 2px solid #e9ecef !important;" scope="col" rowspan="2">{{ $t('classic_daily_summary.link_sheet_begin') }}</th>
                    <th scope="col" rowspan="2">{{ $t('classic_daily_summary.link_sheet_inter') }}</th>
                    <th scope="col" rowspan="2">{{ $t('classic_daily_summary.link_sheet_end') }}</th>
                    <th scope="colgroup" colspan="8">{{ $t('classic_daily_summary.intervention_features') }}</th>
                    <th scope="colgroup" rowspan="2">{{ $t('classic_daily_summary.winter_driving_conditions') }}</th>
                  </tr>
                  <tr>
                    <th scope="col">{{ $t('ager_label') }}</th>
                    <th scope="col">{{ $t('uer_label') }}</th>
                    <th scope="col">{{ $t('cei_label') }}</th>
                    <th scope="col">{{ $t('circuits_label') }}</th>
                    <th scope="col">{{ $t('sections') }}</th>
                    <th scope="col">{{ $t('axis') }}</th>
                    <th scope="col">{{ $t('classic_daily_summary.patrolling') }}</th>
                    <th scope="col">{{ $t('classic_daily_summary.precurative_treatment') }}</th>
                    <th scope="col">{{ $t('classic_daily_summary.curative_treatment') }}</th>
                    <th scope="col">{{ $t('classic_daily_summary.sore_points') }}</th>
                    <th scope="col">{{ $t('classic_daily_summary.patrolling') }}</th>
                    <th scope="col">{{ $t('classic_daily_summary.precurative_treatment') }}</th>
                    <th scope="col">{{ $t('classic_daily_summary.curative_treatment') }}</th>
                    <th scope="col">{{ $t('classic_daily_summary.sore_points') }}</th>
                    <th scope="col">{{ $t('classic_daily_summary.intervention_begin_intervention') }}</th>
                    <th scope="col">{{ $t('classic_daily_summary.intervention_end_intervention') }}</th>
                    <th scope="col">{{ $t('classic_daily_summary.dry_salt') }}</th>
                    <th scope="col">{{ $t('classic_daily_summary.brine') }}</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="circuit in circuitListToDisplay" :key="circuit.id">
                    <!-- Circuits --->
                    <td v-if=circuit.agerSize :rowspan=circuit.agerSize>{{ circuit.agerLabel }}</td>
                    <td v-if=circuit.uerSize :rowspan=circuit.uerSize>{{ circuit.uerLabel }}</td>
                    <td v-if=circuit.ceiSize :rowspan=circuit.ceiSize>{{ circuit.ceiLabel }}</td>
                    <td>{{ circuit.number }}</td>
                    <td>{{ circuit.section }}</td>
                    <td>{{ circuit.axis }}</td>
                    <!-- Forecast --->
                    <td style="border-left: 2px solid #e9ecef !important;" v-if=circuit.ceiSize :rowspan=circuit.ceiSize>{{ forecastCell(circuit) }}</td>
                    <td>{{ forecastPatrolCell(circuit) }}</td>
                    <td>{{ forecastPrecurCell(circuit) }}</td>
                    <td>{{ forecastCurCell(circuit) }}</td>
                    <td v-if=circuit.ceiSize :rowspan=circuit.ceiSize>{{ forecastPointsCell(circuit) }}</td>
                    <!-- Link --->
                    <td style="border-left: 2px solid #e9ecef !important;" v-if=circuit.ceiSize :rowspan=circuit.ceiSize>{{ linkStartCell(circuit) }}</td>
                    <td v-if=circuit.ceiSize :rowspan=circuit.ceiSize>{{ linkInterCell(circuit) }}</td>
                    <td v-if=circuit.ceiSize :rowspan=circuit.ceiSize>{{ linkEndCell(circuit) }}</td>
                    <td>{{ linkPatrolCell(circuit) }}</td>
                    <td>{{ linkPrecurCell(circuit) }}</td>
                    <td>{{ linkCurCell(circuit) }}</td>
                    <td v-if=circuit.ceiSize :rowspan=circuit.ceiSize>{{ linkPointsCell(circuit) }}</td>
                    <td>{{ linkBeginHour(circuit) }}</td>
                    <td>{{ linkEndHour(circuit) }}</td>
                    <td>{{ linkSalt(circuit) }}</td>
                    <td>{{ linkBrine(circuit) }}</td>
                    <td>{{ linkTrafficCondition(circuit) }}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </AkFormView>
</template>